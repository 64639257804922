import CompanyInfo from "./CompanyInfo";
import NavigationBar from "./NavigationBar";
import MobileNavigationBar from "./MobileNavigationBar";

import './common.css';

const PageHeader = () => {
return (
    <div className = 'page-Header'>
        <div className = 'page-Header-Container'>
            <div className = 'page-Header-Item'>
                <CompanyInfo/>
            </div>
            <div className = 'page-Header-Item'>
                <NavigationBar/>
                <MobileNavigationBar/>
            </div>
        </div>
    </div>
    )
}

export default PageHeader