import React from 'react'
import ContactUsHeader from './ContactUsHeader'
import ContactUsDescription from './ContactUsDescription'
import './contact.css'

const ContactUs = () => {
  return (
    
    <div className = 'ContactUs-Container'>
        <ContactUsHeader/>
        <ContactUsDescription/>
    </div>
  )
}

export default ContactUs