import CompanyDetails from "../../assets/common/company-name.png"
import { NavLink } from "react-router-dom"

const CompanyInfo = () => {
    return (
        <div className = 'companyinfo-container noSelect'>
            <NavLink to = '/'><img className='companyinfo-image' loading="eager" src = {CompanyDetails}/></NavLink>
        </div>
    )
}

export default CompanyInfo