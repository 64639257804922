import React from 'react'
import flowerHeader from "../../assets/services/services.jpg"

const ServicesHeader = () => {
    return (
        <div className = 'Services-Header-Container'>
            <div className = 'Services-Header-Sub-Container'>
                <img className = 'Services-Header-Sub-Container-Image' src = {flowerHeader} />
                <div className = 'Services-Header-Sub-Container-Image-Overlay'>
                    <div className = 'Services-Header-Sub-Container-Image-Overlay-Text'>
                        Our Services & Packages <br></br>
                        <p className = 'Services-Header-Sub-Container-Image-Overlay-Subtext'>
                            At Ah Keong Trading, we ensure a fair and transparent pricing for everyone.<br></br>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesHeader