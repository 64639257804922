import React, { useEffect } from 'react'
import './services.css';
import ServicesDescription from './ServicesDescription'
import ServicesRecyclerTable from './ServicesRecyclerTable'
import ServiceCarousel from './ServiceCarousel'
import ServicesRecyclerPriceBox from './ServicesRecyclerPriceBox'
import buddhistCard1 from '../../assets/services/buddhist1.jpg'
import buddhistCard2 from '../../assets/services/buddhist2.jpg'
import taoistCard2 from '../../assets/services/taoist2.jpg'
import taoistCard3 from '../../assets/services/taoist3.jpg'
import christianCard1 from '../../assets/services/christian1.jpg'
import christianCard2 from '../../assets/services/christian2.jpg'
import christianCard3 from '../../assets/services/christian3.jpg'
import catholicCard1 from '../../assets/services/catholic1.jpg'
import freeThinkerCard1 from '../../assets/services/freethinker1.jpg'
import sokaCard1 from '../../assets/services/soka1.jpg'

const Service = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, []);

    const pathname = window.location.pathname;

    let serviceID = 0;
    if(pathname ===  "/services/buddhist") serviceID = 1;
    else if(pathname ===  "/services/taoist") serviceID = 2;
    else if(pathname ===  "/services/catholic") serviceID = 3;
    else if(pathname ===  "/services/christian") serviceID = 4;
    else if(pathname ===  "/services/soka") serviceID = 5;
    else if(pathname ===  "/services/freethinker") serviceID = 6;

    const packageData = [
        //buddhist
        {
            packageID: 0,
            packageName: "Buddhist Package",
            packageImages: [
                buddhistCard1,
                buddhistCard2
            ],
            packageDescription: "",
            packageHeader: "Standard Buddhist Funeral Package",
            packagePrices: [
                {
                    threeDays: "$5,800",
                    fiveDays: "$6,800"
                }
            ],
            packageItems: [
                {
                    preFuneral :[
                        "Casket",
                        "Pallbearers and Hearse",
                        "Encoffin Necessities"
                    ]
                },
                {
                    funeralPreparation: [
                        "Canvas Tentage", 
                        "Carpet", 
                        "15 Square Tables",
                        "10 Circular Tables", 
                        "100 Chairs",
                        "1 Fridge",
                        "Portable Toilet"
                    ]
                },
                {
                    funeralDecoration: [
                        "Photo Enlargement",
                        "Photo Flower Wreath", 
                        "Altar Arrangement",
                        "2 Table Bouquet on Buddha's Altar", 
                        "2 Table Bouquet on Altar"
                    ]
                },
                {
                    funeralProcession: [
                        "Buddhist Monk Chanting Session",
                        "Vegetarian Food & Fruit Offerings"
                    ]
                },
                {    
                    funeralTransport: [
                        "1 Air-Conditioned Bus (2 Way Trip)"
                    ]
                },
                {
                    funeralMisc: [
                        "10 Cartons of Packet Drinks", 
                        "5 Cartons of Plain Water", 
                        "10 Packets of Assorted Sweets",
                        "4 Packets of Peanuts", 
                        "4 Packets of Melon Seeds", 
                        "2 Packets of Table Cloth", 
                        "1 Packet of Trash Bag", 
                        "1 Packet of Red Packets", 
                        "1 Packet of Red Strings", 
                        "1 Packet of Disposable Paper Plates", 
                        "3 Rolls of Disposable Paper Cups"
                    ]
                }
            ]
        },
        //taoist
        {
            packageID: 1,
            packageName: "Taoist Package",
            packageImages: [
                taoistCard3,
                taoistCard2
            ],
            packageDescription: "",
            packageHeader: "Standard Taoist Funeral Package",
            packagePrices: [
                {
                    threeDays: "$13,800",
                    fiveDays: "$15,800"
                }
            ],
            packageItems: [
                {
                    preFuneral :[
                        "Casket",
                        "Pallbearers and Hearse",
                        "Encoffin Necessities",
                        "Embalming and Cosmetic Make-Up Services"
                    ]
                },
                {
                    funeralPreparation: [
                        "Canvas Tentage", 
                        "Carpet", 
                        "20 Square Tables",
                        "10 Circular Tables", 
                        "100 Chairs",
                        "1 Fridge",
                        "Portable Toilet"
                    ]
                },
                {
                    funeralDecoration: [
                        "Photo Enlargement",
                        "Photo Flower Wreath", 
                        "Altar Arrangement",
                        "2 Table Bouquet on Altar"
                    ]
                },
                {
                    funeralProcession: [
                        "Taoist Priest Chanting (3 Priests)",
                        "Food & Fruit Offerings",
                        "1 Paper House (6 Feet), Gold and Silver Mountains",
                        "1 box of Gold Paper",
                        "1 box of Silver Paper",
                        "1 box of Afterlife Money",
                        "Incense Sticks & Candles",
                        "1 Metal Cage for Burning of Offerings",
                        "1 Pair of Big Lantern",
                        "Golden Guardian and Jade Maiden"
                    ]
                },
                {    
                    funeralTransport: [
                        "1 Air-Conditioned Bus (2 Way Trip)"
                    ]
                },
                {
                    funeralMisc: [
                        "10 Cartons of Packet Drinks", 
                        "10 Cartons of Plain Water", 
                        "10 Packets of Assorted Sweets",
                        "4 Packets of Peanuts", 
                        "4 Packets of Melon Seeds", 
                        "2 Packets of Table Cloth", 
                        "1 Packet of Trash Bag", 
                        "1 Packet of Red Packets", 
                        "1 Packet of Red Strings", 
                        "1 Packet of Disposable Paper Plates", 
                        "3 Rolls of Disposable Paper Cups"
                    ]
                }
            ]
        },
        //catholic
        {
            packageID: 2,
            packageName: "Catholic Package",
            packageImages: [
                catholicCard1
            ],
            packageDescription: "",
            packageHeader: "Standard Catholic Funeral Package",
            packagePrices: [
                {
                    threeDays: "$5,800",
                    fiveDays: "$6,800"
                }
            ],
            packageItems: [
                {
                    preFuneral :[
                        "Casket",
                        "Pallbearers and Hearse",
                        "Encoffin Necessities",
                        "Embalming and Cosmetic Make-Up Services"
                    ]
                },
                {
                    funeralPreparation: [
                        "Canvas Tentage", 
                        "Carpet", 
                        "15 Square Tables",
                        "10 Circular Tables", 
                        "100 Chairs",
                        "1 Fridge",
                        "Portable Toilet"
                    ]
                },
                {
                    funeralDecoration: [
                        "Photo Enlargement",
                        "Catholic Memorial Setup",
                        "1 Set of PA Sound System",
                        "1 Photo Flower Wreath",
                        "1 Table Bouquet"
                    ]
                },
                {
                    funeralProcession: [
                    ]
                },
                {    
                    funeralTransport: [
                        "1 Air-Conditioned Bus (2 Way Trip)"
                    ]
                },
                {
                    funeralMisc: [
                        "10 Cartons of Packet Drinks", 
                        "5 Cartons of Plain Water", 
                        "10 Packets of Assorted Sweets",
                        "4 Packets of Peanuts", 
                        "4 Packets of Melon Seeds", 
                        "2 Packets of Table Cloth", 
                        "1 Packet of Trash Bag", 
                        "1 Packet of Red Strings", 
                        "1 Packet of Disposable Paper Plates", 
                        "3 Rolls of Disposable Paper Cups"
                    ]
                }
            ]
        },
        //christian
        {
            packageID: 3,
            packageName: "Christian Package",
            packageImages: [
                christianCard2,
                christianCard1,
                christianCard3
            ],
            packageDescription: "",
            packageHeader: "Standard Christian Funeral Package",
            packagePrices: [
                {
                    threeDays: "$5,800",
                    fiveDays: "$6,800"
                }
            ],
            packageItems: [
                {
                    preFuneral :[
                        "Casket",
                        "Pallbearers and Hearse",
                        "Encoffin Necessities",
                        "Embalming and Cosmetic Make-Up Services"
                    ]
                },
                {
                    funeralPreparation: [
                        "Canvas Tentage", 
                        "Carpet", 
                        "15 Square Tables",
                        "10 Circular Tables", 
                        "100 Chairs",
                        "1 Fridge",
                        "Portable Toilet"
                    ]
                },
                {
                    funeralDecoration: [
                        "Photo Enlargement",
                        "Christian Memorial Setup",
                        "1 Set of PA Sound System",
                        "1 Photo Flower Wreath",
                        "1 Table Bouquet"
                    ]
                },
                {
                    funeralProcession: [
                    ]
                },
                {    
                    funeralTransport: [
                        "1 Air-Conditioned Bus (2 Way Trip)"
                    ]
                },
                {
                    funeralMisc: [
                        "10 Cartons of Packet Drinks", 
                        "5 Cartons of Plain Water", 
                        "10 Packets of Assorted Sweets",
                        "4 Packets of Peanuts", 
                        "4 Packets of Melon Seeds", 
                        "2 Packets of Table Cloth", 
                        "1 Packet of Trash Bag", 
                        "1 Packet of Red Strings", 
                        "1 Packet of Disposable Paper Plates", 
                        "3 Rolls of Disposable Paper Cups"
                    ]
                }
            ]
        },
        //soka
        {
            packageID: 4,
            packageName: "Soka Package",
            packageImages: [
                sokaCard1
            ],
            packageDescription: "",
            packageHeader: "Standard Soka Funeral Package",
            packagePrices: [
                {
                    threeDays: "$5,800",
                    fiveDays: "$6,800"
                }
            ],
            packageItems: [
                {
                    preFuneral :[
                        "Casket",
                        "Pallbearers and Hearse",
                        "Encoffin Necessities",
                        "Embalming and Cosmetic Make-Up Services"
                    ]
                },
                {
                    funeralPreparation: [
                        "Canvas Tentage", 
                        "Carpet", 
                        "15 Square Tables",
                        "10 Circular Tables", 
                        "100 Chairs",
                        "1 Fridge",
                        "Portable Toilet"
                    ]
                },
                {
                    funeralDecoration: [
                        "Soka Funeral Decoration Setup",
                        "Photo Enlargement",
                        "Photo Flower Wreath", 
                        "1 Set of PA Sound System"
                    ]
                },
                {
                    funeralProcession: [
                    ]
                },
                {    
                    funeralTransport: [
                        "1 Air-Conditioned Bus (2 Way Trip)"
                    ]
                },
                {
                    funeralMisc: [
                        "10 Cartons of Packet Drinks", 
                        "5 Cartons of Plain Water", 
                        "10 Packets of Assorted Sweets",
                        "4 Packets of Peanuts", 
                        "4 Packets of Melon Seeds", 
                        "2 Packets of Table Cloth", 
                        "1 Packet of Trash Bag", 
                        "1 Packet of Red Strings", 
                        "1 Packet of Disposable Paper Plates", 
                        "3 Rolls of Disposable Paper Cups"
                    ]
                }
            ]
        },
        //free thinker
        {
            packageID: 5,
            packageName: "Free Thinker Package",
            packageImages: [
                freeThinkerCard1
            ],
            packageDescription: "",
            packageHeader: "Standard Free Thinker Funeral Package",
            packagePrices: [
                {
                    threeDays: "$5,800",
                    fiveDays: "$6,800"
                }
            ],
            packageItems: [
                {
                    preFuneral :[
                        "Casket",
                        "Pallbearers and Hearse",
                        "Encoffin Necessities",
                        "Embalming and Cosmetic Make-Up Services"
                    ]
                },
                {
                    funeralPreparation: [
                        "Canvas Tentage", 
                        "Carpet", 
                        "15 Square Tables",
                        "10 Circular Tables", 
                        "100 Chairs",
                        "1 Fridge",
                        "Portable Toilet"
                    ]
                },
                {
                    funeralDecoration: [
                        "Free Thinker Funeral Decoration Setup",
                        "Photo Enlargement",
                        "Photo Flower Wreath", 
                        "Table Bouquet",
                        "1 Set of PA Sound System"
                    ]
                },
                {
                    funeralProcession: [
                    ]
                },
                {    
                    funeralTransport: [
                        "1 Air-Conditioned Bus (2 Way Trip)"
                    ]
                },
                {
                    funeralMisc: [
                        "10 Cartons of Packet Drinks", 
                        "5 Cartons of Plain Water", 
                        "10 Packets of Assorted Sweets",
                        "4 Packets of Peanuts", 
                        "4 Packets of Melon Seeds", 
                        "2 Packets of Table Cloth", 
                        "1 Packet of Trash Bag", 
                        "1 Packet of Red Strings", 
                        "1 Packet of Disposable Paper Plates", 
                        "3 Rolls of Disposable Paper Cups"
                    ]
                }
            ]
        }
    ]

    return (
        <div className = 'Service-Page-Container'>
            <h2 className = 'Service-Page-Header'>{packageData[serviceID-1].packageName}</h2>
            <div className = 'Service-Page-Sub-Container'>
                <div className = 'Services-Page-Image-Gallery'>
                    <ServiceCarousel images = {packageData[serviceID-1].packageImages}/>
                </div>
                <ServicesDescription headerText = {packageData[serviceID-1].packageHeader} content = {packageData[serviceID-1].packageDescription}/>
                <ServicesRecyclerPriceBox content = {packageData[serviceID-1].packagePrices}/>
                <ServicesRecyclerTable headerText =  'Body Collection and Professional Embalming' content = {packageData[serviceID-1].packageItems[0].preFuneral}/>
                <ServicesRecyclerTable headerText =  'Preparation of Ceremony' content = {packageData[serviceID-1].packageItems[1].funeralPreparation}/>
                <ServicesRecyclerTable headerText =  'Floral Arrangement' content = {packageData[serviceID-1].packageItems[2].funeralDecoration}/>
                <ServicesRecyclerTable headerText =  'Memorial Ceremony and Procession' content = {packageData[serviceID-1].packageItems[3].funeralProcession}/>
                <ServicesRecyclerTable headerText =  'Transport' content = {packageData[serviceID-1].packageItems[4].funeralTransport}/>
                <ServicesRecyclerTable headerText =  'Miscellaneous Items' content = {packageData[serviceID-1].packageItems[5].funeralMisc}/>
            </div>
            <br></br>
        </div>
    )
}

export default Service