import React from 'react'
import './about.css';
import AboutUsCompany from './AboutUsCompany';
import AboutUsHeader from './AboutUsHeader';

const AboutUs = () => {
    return (
        <div className = 'AboutUs-Container'>
            <AboutUsHeader/>
            <div className = 'AboutUs-Sub-Container'> 
                <AboutUsCompany/>
            </div>
        </div>
    )
}

export default AboutUs