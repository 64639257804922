import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageHeader from './components/common/PageHeader';
import Homepage from './components/homepage/Homepage';
import Footer from './components/common/Footer';
import AboutUs from './components/about/AboutUs';
import Service from './components/services/Service';
import Services from './components/services/Services';
import ContactUs from './components/contact/ContactUs';

function App() {
	return (
		<Router>
			<div className="App">
				<PageHeader/>
				<Routes>
					<Route path = '/' exact element = {<Homepage/>}/>
					<Route path = '/services' exact element = {<Services/>}/>
					<Route path = '/services/*' exact element = {<Service/>}/>
					<Route path = '/about' exact element = {<AboutUs/>}/>
					<Route path = '/contact-us' exact element = {<ContactUs/>}/>
				</Routes>     
				<Footer/>
			</div>
		</Router>
	);
}

export default App;
