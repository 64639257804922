import React, { useEffect } from 'react'
import ServiceCarouselItem from './ServiceCarouselItem';
import ServiceCarouselItemIndicator from './ServiceCarouselItemIndicator';
import { useState } from 'react';

const ServiceCarousel = (carouselData) => {

    const [activeSlide, setActiveSlide] = useState(0);
    const [paused, setPaused] = useState(false);

    const updateSlide = (newSlide) => {
        if(newSlide < 0){
            newSlide = carouselData.images.length - 1;
        }
        else if(newSlide > carouselData.images.length - 1){
            newSlide = 0;
        }
        setActiveSlide(newSlide);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(!paused){
                updateSlide(activeSlide + 1);
            }
        }, 5000);

        return () => {
            if(interval) {
                clearInterval(interval);
            }
        }
    });

    return (
        <div className = 'Service-Page-Carousel-Container' onMouseEnter={() => setPaused(true)} onMouseLeave = {() => setPaused(false)}>
            <div className = 'Service-Page-Carousel-Image-Container' style = {{ transform: `translateX(-${activeSlide * 100}%)` }}>
                {carouselData.images.map((image) => (
                    <ServiceCarouselItem image = {image} key = {image}/>
                ))}
            </div>
            {carouselData.images.length > 1 ?
                <><div className='Service-Page-Carousel-Indicators-Container'>
                    {carouselData.images.map((image, index) => (
                        <ServiceCarouselItemIndicator onClick={() => { updateSlide(index); }} key={image} activeIndex={activeSlide} index={index} />
                    ))}
                </div><div className='Service-Page-Carousel-Button-Container'>
                        <span className='Service-Page-Carousel-Left-Button' onClick={() => { updateSlide(activeSlide - 1); }}>&#10094;</span>
                        <span className='Service-Page-Carousel-Right-Button' onClick={() => { updateSlide(activeSlide + 1); }}>&#10095;</span>
                    </div>
                </>
                : <></>}
        </div>
    )
}

export default ServiceCarousel