import './homepage.css';
import BannerDisplay from './BannerDisplay';
import SkinnyBanner from './SkinnyBanner';
import ContactUs from './ContactUs';
import Packages from './Packages';

const Homepage = () => {
    return (
        <div className = 'homepage-container'>
            <BannerDisplay/>
            <ContactUs/>
            <SkinnyBanner/>
            <Packages bckgColor = "var(--color1)" cardColor="white"/>
        </div>
    )
}

export default Homepage