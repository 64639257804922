import React from 'react'

const ServicesDescription = ( {headerText, content} ) => {

    return (
        <div className = 'Services-Page-Recycle-Sub-Container'>
            <h3 className = 'Services-Description-Container-Header'>{headerText}</h3>
            <div className = 'Services-Description-Container-Content'>
                <p>
                    {content}
                </p>
            </div>
        </div>
    )
}

export default ServicesDescription