import React from 'react'
import davidPhoto from '../../assets/aboutus/david.jpg'

const AboutUsCompany = () => {
    return (
        <div className = 'AboutUs-Sub-Container-Flexbox'>
            <h2 className = 'AboutUs-Header'>About Ah Keong Trading</h2>
            <div className = 'AboutUs-Sub-Container-Founder'>
                <div className = 'AboutUs-Sub-Container-Image-Container'>
                    <div className = 'AboutUs-Sub-Container-Image'>
                        <img src = {davidPhoto} className = 'About-Us-Founder-Photo'/>
                    </div>
                </div>
                
                <div className = 'AboutUs-Sub-Container-Writeup'>
                    <p className='AboutUs-Writeup'>
                        Ah Keong Trading was founded in 2012 with the mission to provide professional and quality services to the bereaved families in Singapore.
                        We are committed to provide the best service for the deceased and your family.
                        <br></br><br></br>
                        We are an one-stop funeral service company that provides customised services and packages for all your needs.
                        We believe that quality services do not need to come with a premium price.
                        We provide a transparent pricing policy whereby the price of every item is made known to you. You will know how much you are paying for each and every item or service.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutUsCompany