import React from 'react'
import homepageBannerMobile from '../../assets/homepage/homepageBanner.jpg'

const BannerDisplay = () => {
    return (
        <div className = 'Banner-Display-Container'>
            <div className = 'Banner-Display-Sub-Container'>
                <img className = 'Banner-Display-Image' src = {homepageBannerMobile} loading="eager"/>
                <img className = 'Banner-Display-Image-Mobile' src = {homepageBannerMobile} loading="eager"/>
            </div>
        </div>
    )
}

export default BannerDisplay