import React from 'react'

const ContactUs = () => {
    return (
        <div className = 'Contact-Us-Container'>
            <div className = 'Contact-Us-Sub-Container'>
                <h2 className = 'Container-Header'>Funeral Services in Singapore</h2>
                <h2 className = 'Container-Sub-Header'>By Ah Keong (David)</h2>
                <div className = 'Contact-Us-Write-Up'>
                    At Ah Keong Trading, we are committed to provide professional and quality services to the bereaved families in Singapore.
                    We understand that it is a difficult period of time and we are committed to ensure that the funeral proceedings are arranged to lessen the burdens of the family.
                    <br></br><br></br>
                    We are dedicated to be an one-stop service for all funeral needs and services. 
                    We provide a wide range of funeral services and packages for all religions and dialect groups.
                    Allow us to handle and take care of the proceedings, prayers and admin matters while you have an ease of mind in such trying times.
                    We only wish and want the best for the deceased and your family.
                    <br></br><br></br>
                    If you have any queries, you can reach us at <b>+65 9295 0779</b> (24-hours hotline).
                </div>
            </div>
        </div>
    )
}

export default ContactUs