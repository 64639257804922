import React from 'react'

const SkinnyBanner = () => {
    return (
        <div className = 'skinny-banner-container'>
            <h2 className = 'Container-Header'>What to do when a loved one passes on?</h2>
            <h2 className = 'Container-Sub-Header'>Do not panic, call us.</h2>
            <div className = 'skinny-banner-sub-container'>
                <div className = 'skinny-banner-box' style = { {backgroundColor : "var(--color3)"} }>
                    <h3 className = 'skinny-banner-box-header'>Report the Death</h3>
                    <div className = 'skinny-banner-box-text'>
                        If the death occurred at home, a doctor will be required to visit your home to certify the death.
                        <br></br><br></br>
                        If the death occurred in the hospital, the doctors in the hospital will register the death.
                    </div>
                     </div>
                <div className = 'skinny-banner-box' style = { {backgroundColor : "var(--color3)"} }>
                    <h3 className = 'skinny-banner-box-header'>Obtain the Digital Death Certificate</h3>
                    <div className = 'skinny-banner-box-text'>
                        You will need the following:
                        <br></br><br></br>
                        1. Death Certificate Number
                        <br></br>
                        2. Deceased's NRIC, FIN or Passport Number
                        <br></br>
                        3. Date of death
                        <br></br><br></br>
                        In order to download the digital death certificate.
                    </div>
                </div>
                <div className = 'skinny-banner-box' style = { {backgroundColor :"var(--color3)"} }>
                    <h3 className = 'skinny-banner-box-header'>Arrange the Funeral</h3>
                    <div className = 'skinny-banner-box-text'>
                        Having to arrange for the funeral while grieving over the loss of a loved one can be a huge challenge.
                        <br></br><br></br>
                        It can be confusing and daunting with not knowing what to do. 
                        <br></br><br></br>
                        Entrust this task of arranging the funeral for your loved one to us.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkinnyBanner