import React from 'react'
import { Link } from 'react-router-dom'

const PackageCard = ({packageInfo}) => {

    return (
        <div className = 'Package-Card-Container' style={{backgroundColor : packageInfo.cardColor}}>
            <Link to = {packageInfo.redirectionLink}>
                <img src = {packageInfo.packageImage} loading="lazy" className = 'Package-Card-Container-Image'/>
                <div className = 'Package-Card-Details'>
                    <div className = 'Package-Card-Details-Name'>
                        {packageInfo.packageName}
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default PackageCard