import { NavLink } from "react-router-dom"

const NavigationBar = () => {

    // need to rework how the navlink works with the isActive
    // css also needs to be reworked
    // home, about, services, contact us
    return (
        <div className = 'Navigation-Bar'>
            <ul className = 'Navigation-Bar-List'>
                <li><NavLink to = '/' className = { ( { isActive } ) => isActive ? "Active-Navigation-Bar-List-Item" : "Navigation-Bar-List-Item" }>Home</NavLink></li>
                <li><NavLink to = '/about' className = { ( { isActive } ) => isActive ? "Active-Navigation-Bar-List-Item" : "Navigation-Bar-List-Item" }>About</NavLink></li>
                <li><NavLink to = '/services' className = { ( { isActive } ) => isActive ? "Active-Navigation-Bar-List-Item" : "Navigation-Bar-List-Item" }  >Services</NavLink></li>
                <li><NavLink to = '/contact-us' className = { ( { isActive } ) => isActive ? "Active-Navigation-Bar-List-Item" : "Navigation-Bar-List-Item" }>Contact Us</NavLink></li>
            </ul>
        </div>
    )
}

export default NavigationBar