import React from 'react'

const ServicesRecyclerPriceBox = (content) => {

    return (
        <div className = 'Services-Page-Price-Container'>
            <table className = 'Services-Page-Price-Container-Table'>
                <tbody>
                    <tr>
                        <td className = 'Services-Page-Price-Container-Table-Left'>3 Days</td>
                        <td className = 'Services-Page-Price-Container-Table-Right'>{content.content[0].threeDays}</td>
                    </tr>
                    <tr>
                        <td className = 'Services-Page-Price-Container-Table-Left'>5 Days</td>
                        <td className = 'Services-Page-Price-Container-Table-Right'>{content.content[0].fiveDays}</td>
                    </tr>
                </tbody>
                
            </table>    
        </div>
    )
}

export default ServicesRecyclerPriceBox