import React from 'react'
import ServicesHeader from './ServicesHeader';
import Packages from '../homepage/Packages';

const Services = () => {

    return (
        <div className = 'Services-Page-Container'>
            <ServicesHeader/>
            <Packages bckgColor = "white" cardColor="var(--color1)"/>
        </div>
    )
}

export default Services