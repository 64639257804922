import React from 'react'

const ContactUsDescription = () => {
    return (
        <div className='ContactUs-Sub-Container'>
            
            <p className='AboutUs-Writeup'>
                <h1 className = 'ContactUs-Font'>Contact Us</h1>
                <h3 className = 'ContactUs-Font ContactUs-20px'>We are here to help</h3>
                Our team is available 24/7 and is here to support you to our best efforts. <br></br>
                Please do not hesistate to call us at anytime for any queries. <br></br>
                <br></br>

                <h1 className = 'ContactUs-Font ContactUs-Header'>Phone</h1>
                <p className = 'ContactUs-Writeup'>9295 0779</p>
                
                <h1 className = 'ContactUs-Font ContactUs-Header'>Address</h1>
                <p className = 'ContactUs-Writeup'>
                    3018 BEDOK NORTH STREET 5
                    <br></br>
                    #04-09
                    <br></br>
                    SINGAPORE 486132
                </p>
                
            </p>

        </div>
    )
}

export default ContactUsDescription