import React from 'react'

const ServicesRecyclerTable = ( {headerText, content} ) => {

    let displayVar = true;
    if(content[0] === undefined){
        displayVar = false;
    }

    return (
        <div className = 'Services-Page-Recycle-Sub-Container' style = { displayVar ? {} : {display: 'none'}}>
            <h4 className = 'Services-Page-Recycle-Sub-Container-Sub-Header'>{headerText}</h4>
            <div className = 'Services-Page-Recycle-Sub-Container-Content'>
                <ul>
                    {content.map((packageData, index) => (
                        <li key = {index}><span>{packageData}</span></li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default ServicesRecyclerTable