import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineClose } from 'react-icons/md';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const MobileNavigationBar = () => {

    const [mobileMenuToggle, setMobileMenu] = useState(false);

    return (
        <div className = 'Mobile-Navigation-Bar'>
            <GiHamburgerMenu className = 'Mobile-Navigation-Bar-Button'
                onClick = { () => setMobileMenu(true) }
            />
            {mobileMenuToggle && (
                <div className = "Mobile-Navigation-Menu Animation-Slide-Bottom">
                    <MdOutlineClose className = 'Mobile-Navigation-Bar-Button-Close' onClick = {() => setMobileMenu(false)}></MdOutlineClose>
                    <ul className = "Mobile-Navigation-Menu-List">
                        <li><NavLink to = '/' className = 'Mobile-Navigation-Menu-List-Item' onClick = {() => setMobileMenu(false)}>Home</NavLink> </li>
                        <li><NavLink to = '/about' className = 'Mobile-Navigation-Menu-List-Item' onClick = {() => setMobileMenu(false)}>About</NavLink> </li>
                        <li><NavLink to = '/services' className = 'Mobile-Navigation-Menu-List-Item' onClick = {() => setMobileMenu(false)}>Services</NavLink> </li>
                        <li><NavLink to = '/contact-us' className = 'Mobile-Navigation-Menu-List-Item' onClick = {() => setMobileMenu(false)}>Contact Us</NavLink> </li>
                    </ul>
                </div>
            )} 
        </div>
        )
}

export default MobileNavigationBar