import React from 'react'

const Footer = () => {
    return (
        <div className = 'footer-container'>
            Copyright &copy; 2022 Ah Keong Trading . All Rights Reserved 
            <br></br>Website by Junwen
        </div>
    )
}

export default Footer