import React from 'react';
import PackageCard from './PackageCard';
import buddhistCard from '../../assets/homepage/buddhist.jpg'
import taoistCard from '../../assets/homepage/taoist.jpg'
import christianCard from '../../assets/homepage/christian.jpg'
import catholicCard from '../../assets/homepage/catholic.jpg'
import freeThinkerCard from '../../assets/homepage/freethinker.jpg'
import sokaCard from '../../assets/homepage/soka.jpg'


const Packages = (color) => {

    const cardColor = color.cardColor;

    const listOfPackages = [
        {
            packageName: 'Buddhist Package',
            packageID: '1',
            packageImage: buddhistCard,
            redirectionLink: '/services/buddhist',
            cardColor: cardColor
        },
        {
            packageName: 'Taoist Package',
            packageID: '2',
            packageImage: taoistCard,
            redirectionLink: '/services/taoist',
            cardColor: cardColor
        },
        {
            packageName: 'Catholic Package',
            packageID: '3',
            packageImage: catholicCard,
            redirectionLink: '/services/catholic',
            cardColor: cardColor
        },
        {
            packageName: 'Christian Package',
            packageID: '4',
            packageImage: christianCard,
            redirectionLink: '/services/christian',
            cardColor: cardColor
        },
        {
            packageName: 'Soka Package',
            packageID: '5',
            packageImage: sokaCard,
            redirectionLink: '/services/soka',
            cardColor: cardColor
        },
        {
            packageName: 'Free Thinker Package',
            packageID: '6',
            packageImage: freeThinkerCard,
            redirectionLink: '/services/freethinker',
            cardColor: cardColor
        }
    ];

    return (
        <div className = 'Packages-Container' style = {{ backgroundColor : color.bckgColor }}>
            <h2 className = 'Container-Header'>Packages</h2>
            <div className = 'Packages-Sub-Container'>
                {listOfPackages.map((packageInfo, index) => (
                    <PackageCard packageInfo = {packageInfo} key = {packageInfo.packageID}/>
                ))}
            </div>
        </div>
    )
}

export default Packages