import React from 'react'
import flowerHeader from "../../assets/services/services.jpg"

const AboutUsHeader = () => {
    return (
        <div className = 'AboutUs-Header-Container'>
            <div className = 'AboutUs-Header-Sub-Container'>
                <img className = 'AboutUs-Header-Sub-Container-Image' src = {flowerHeader} />
                <div className = 'Services-Header-Sub-Container-Image-Overlay'>
                    <div className = 'Services-Header-Sub-Container-Image-Overlay-Text'>
                        About Us <br></br>
                        <p className = 'Services-Header-Sub-Container-Image-Overlay-Subtext'>
                            We are committed to give our best and to assist you.<br></br>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsHeader